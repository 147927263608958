// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//


html, body {

    background-color: #ebebea!important;
    border:none!important;

    line-height: 1.6!important;
}

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;
$border-style:                solid !default;
$border-color:                $gray-300 !default;
$border-color-translucent:    rgba($black, .175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .5rem !default;
$border-radius-xl:            1rem !default;
$border-radius-xxl:           2rem !default;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables
// fusv-disable
$border-radius-2xl:           $border-radius-xxl !default; // Deprecated in v5.3.0
// fusv-enable

// scss-docs-start box-shadow-variables
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;
