
// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;



// The color of the SVG loader should be modified manually.
// See assets/svg/components/circle-preloader.svg

/* raleway-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/raleway-v28-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* raleway-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/raleway-v28-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* raleway-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/raleway-v28-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

$primary:                                                   #a77c15!default;

$font-family-base:                                          "Raleway", sans-serif;

// Colors
$body-color:                                                #ebebea!important;

// Avatar sizes

$avatar-width-xxl :                                         10rem;
$avatar-height-xxl:                                         10rem;


h2 { color: $primary}

.text-light {

  font-style: normal!important;
  font-weight: 200!important;
}

.accordion-button {
    background: none!important;
}




