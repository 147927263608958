// Front Default Color Variables
// =========================================================

$root-path:                                                 "../";


// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//


// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;


.component_content {
    display:none!important;
}

.component_option_thumbnails .selected {

   @extend .bg-soft-primary;
   @extend .border-primary;
// border-width: medium!important;
//    @extend .text-white;

.price {@extend .ms-4;}
//    .price {@extend .text-white;}
//    h6 {@extend .text-white;}
}

.component_option_thumbnails  .price {@extend .ms-2;}

.component_option_thumbnail.selected:not(.loading) button:after {
    @extend .ms-2 ;
    @extend .btn ;
    @extend .btn-success ;
    @extend .text-white ;
 


}


.woocommerce div.product p.price, .woocommerce div.product span.price {
    color: #77a464;
    font-size: 1em!important;
}

.composite_price span.woocommerce-Price-amount {

    font-size: 1.3125rem!important;
    font-weight: 600;
    line-height: 1.2;
    color: #1a1919;
  }


  .component_option_thumbnail.selected:not(.loading) button:after {
   height:30px !important;
   width:30px !important;
 

}



  .composite_form .composite_price p.price, .single-product .composite_form .composite_price p.price {
    margin: 0 !important;
    line-height: 1.2em!important;
}

.component_option_thumbnail.loading button.component_option_thumbnail_select, .component_option_thumbnail.selected button.component_option_thumbnail_select {
    pointer-events: none;
opacity: 0.9;
}

.component_title_wrapper h2 {
    color: #2b2a2a;
}

.composite_button .qty {
   
    @extend .form-control ;
   
 


}

.composite_form:not(.paged) .component .component_inner {
    padding-bottom: 0rem!important;
}

.options-style-thumbnails {

    @extend .card ;
    @extend .rounded-0 ;
    @extend .shadow-sm ;
    @extend .p-2 ;
    @extend .mb-3 ;
}

.component_description p,
.component_description {
    @extend .mb-0;
}

    
.component_option_thumbnail {

    margin-left:0px; 
}


.dropdown-toggle:after {

    border:none!important;
}


html, body {

    background-color: #ebebea!important;
    border:none!important;

    line-height: 1.6!important;
}